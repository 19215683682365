import React from 'react';
import {
    Button,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";

import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { fadeInDown } from 'react-animations';

const fadeInDownAnimation = keyframes`${fadeInDown}`;

const RoadmapHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #fff;
    margin-bottom: 100px;
    animation: 3s ${fadeInDownAnimation};
`;

const RoadmapGroup = styled.div`
    display: flex;
`;

const RoadmapPhase = styled.div`
    display: flex;
    flex-direction: column;
    color: "#fff"

`;

const RoadmapPhaseSub = styled(motion.div)`
    display: flex;
    align-items: center;
    color: ${(props) => (props.started ? "#fff" : "grey")};
`;

const RoadmapPhaseSubHeader = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: Brutal_Light, "Open Sans", arial;
`;

const RoadmapPhaseSubDesc = styled.div`
    display: flex;
    align-items: center;
`;

const RoadmapSubMainBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 800px;
    margin-left: 160px;
    @media (max-width: 1200px) {
        margin-left: 120px;
        width: 400px;
    }

    @media (max-width: 490px) {
        margin-left: 80px;
        width: 300px;
    }
`;

const Roadmap = ({ roadmapRef }) => {
    return (
        <div ref={roadmapRef}>
            <Container>
                <RoadmapHeader>
                    Project Roadmap
                </RoadmapHeader>
                <RoadmapGroup>
                    <div style={{ paddingTop: "70px", marginLeft: "50px" }}>
                        <img
                            alt="..."
                            src={require("./Images/progressLine.png").default}
                            height="1030px"
                        // style={{ transform: "translate(0, -135px)" }}
                        />
                    </div>
                    <RoadmapPhase>
                        <RoadmapPhaseSub whileHover={{ scale: 1.1, borderRadius: "10px", cursor: "pointer" }} started>
                            {/* <div style={{ margin: "0 80px" }}>
                                <img
                                    alt="..."
                                    src={require("./Images/2022q4.png").default}
                                />
                            </div> */}
                            <RoadmapSubMainBlock>
                                <div style={{ display: "flex", flexDirection: "column", padding: "35px 0 15px 0", borderBottom: "1px solid #fff" }}>
                                    <RoadmapPhaseSubHeader style={{ marginBottom: "10px", fontWeight: "bold" }}>2023 Q1</RoadmapPhaseSubHeader>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <RoadmapPhaseSubHeader>Open Metaverse Cloud Service<br></br>(MVP Launch)</RoadmapPhaseSubHeader>
                                        <span className="fas fa-angle-down" style={{ fontSize: "30px", color: "#fff" }} />
                                    </div>
                                </div>
                                <div style={{ fontSize: "20px", color: "#fff", paddingTop: "20px", height: "175px" }}>
                                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre */}
                                </div>
                            </RoadmapSubMainBlock>
                        </RoadmapPhaseSub>
                        <RoadmapPhaseSub whileHover={{ scale: 1.1, borderRadius: "10px", cursor: "pointer" }}>
                            {/* <div style={{ margin: "0 80px" }}>
                                <img
                                    alt="..."
                                    src={require("./Images/2022q4.png").default}
                                />
                            </div> */}
                            <RoadmapSubMainBlock>
                                <div style={{ display: "flex", flexDirection: "column", padding: "35px 0 15px 0", borderBottom: "1px solid grey" }}>
                                    <RoadmapPhaseSubHeader style={{ marginBottom: "10px", fontWeight: "bolder" }}>2023 Q2</RoadmapPhaseSubHeader>
                                    <RoadmapPhaseSubHeader>DynamoVerse001 NFT EarlyBird<br></br>(Sales Starts)</RoadmapPhaseSubHeader>
                                </div>
                                <div style={{ fontSize: "20px", color: "#fff", paddingTop: "20px", height: "175px" }}>
                                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre */}
                                </div>
                            </RoadmapSubMainBlock>
                        </RoadmapPhaseSub>
                        <RoadmapPhaseSub whileHover={{ scale: 1.1, borderRadius: "10px", cursor: "pointer" }}>
                            {/* <div style={{ margin: "0 80px" }}>
                                <img
                                    alt="..."
                                    src={require("./Images/2022q4.png").default}
                                />
                            </div> */}
                            <RoadmapSubMainBlock>
                                <div style={{ display: "flex", flexDirection: "column", padding: "35px 0 15px 0", borderBottom: "1px solid grey" }}>
                                    <RoadmapPhaseSubHeader style={{ marginBottom: "10px", fontWeight: "bolder" }}>2023 Q3</RoadmapPhaseSubHeader>
                                    <RoadmapPhaseSubHeader>Open Metaverse Cloud Service<br></br>(Alpha Launch)</RoadmapPhaseSubHeader>
                                </div>
                                <div style={{ fontSize: "20px", color: "#fff", paddingTop: "20px", height: "175px" }}>
                                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre */}
                                </div>
                            </RoadmapSubMainBlock>
                        </RoadmapPhaseSub>
                        <RoadmapPhaseSub whileHover={{ scale: 1.1, borderRadius: "10px", cursor: "pointer" }}>
                            {/* <div style={{ margin: "0 80px" }}>
                                <img
                                    alt="..."
                                    src={require("./Images/2022q4.png").default}
                                />
                            </div> */}
                            <RoadmapSubMainBlock>
                                <div style={{ display: "flex", flexDirection: "column", padding: "35px 0 15px 0", borderBottom: "1px solid grey" }}>
                                    <RoadmapPhaseSubHeader style={{ marginBottom: "10px", fontWeight: "bolder" }}>2023 Q4</RoadmapPhaseSubHeader>
                                    <RoadmapPhaseSubHeader>DynamoVerse001<br></br>(Launch)</RoadmapPhaseSubHeader>
                                </div>
                                <div style={{ fontSize: "20px", color: "#fff", paddingTop: "20px", height: "175px" }}>
                                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre */}
                                </div>
                            </RoadmapSubMainBlock>
                        </RoadmapPhaseSub>
                    </RoadmapPhase>
                </RoadmapGroup>
            </Container>
        </div>
    )
}

export default Roadmap
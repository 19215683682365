import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { motion, useTransform, useViewportScroll } from "framer-motion";

import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import Logo from './Images/3d_logo.jpeg';
import CompanyNameLogo from './svg/vdl_name.svg';

const NavName = styled.div`
  font-size: 22px;
  font-family: Inter,sans-serif;
`;

const NavBarSocial = () => (
  <Nav className="nav navbar-right" navbar>
    <NavItem>
      <NavLink
        href="https://twitter.com/virtualdynlabs"
        target="_blank"
      >
        <span className="fab fa-twitter" style={{ fontSize: "22px" }} />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        href="https://github.com/Virtual-Dynamic-Labs"
        target="_blank"
      >
        <span className="fab fa-github" style={{ fontSize: "22px" }} />
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        href="https://discord.gg/YYKE9rsQEj"
        target="_blank"
      >
        <span className="fab fa-discord" style={{ fontSize: "22px" }} />
      </NavLink>
    </NavItem>
  </Nav>
)

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 }
      }
    };
  }
};

export default function LandingPageNavbar({ mainRef, teamRef, productRef, deckRef, handleScroll }) {
  const [navbarColor, setNavbarColor] = React.useState("");
  
  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      // setNavbarColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("");
    }
  };
  return (
    <>
      <Navbar className={"fixed-top " + navbarColor} expand="lg" style={{ background: "rgb(0, 0, 0)", backdropFilter: "blur(80px)" }}>
        <Container>
          <div className="navbar-translate">
            <button className="navbar-toggler" id="example-header-6">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
            <NavbarBrand to="/" tag={Link} id="tooltip6619950104">
              <div className="d-flex align-items-center">
                <img src={Logo} width="50px" height="50px" style={{ borderRadius: "25px", marginRight: "8px" }} />
                {/* <NavName style={{fontFamily: 'Inter,sans-serif'}}>Virtual Dynamic Labs</NavName> */}
                <img height="70px" src={CompanyNameLogo} />
              </div>
            </NavbarBrand>
          </div>
          <UncontrolledCollapse navbar toggler="#example-header-6">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <NavbarBrand to="/" tag={Link} id="tooltip6619950104">
                    <div className="d-flex align-items-center">
                      <img src={Logo} width="50px" height="50px" style={{ borderRadius: "25px", marginRight: "8px" }} />
                      {/* <NavName style={{fontFamily: 'Inter,sans-serif'}}>Virtual Dynamic Labs</NavName> */}
                      <img height="70px" src={CompanyNameLogo} />
                    </div>
                  </NavbarBrand>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button className="navbar-toggler" id="example-header-6">
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="mx-auto" navbar style={{ fontSize: "20px" }}>
              <NavItem className="active">
                <NavLink to="/" tag={Link} onClick={() => handleScroll(mainRef.current)}>
                  <NavName>Home</NavName>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/product" tag={Link} onClick={() => handleScroll(productRef.current)}>
                  <NavName>Product</NavName>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/team" tag={Link} onClick={() => teamRef.current.scrollIntoView()}>
                  <NavName>Team</NavName>
                </NavLink>
              </NavItem>
            </Nav>
            <NavBarSocial />
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
    Button,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";

export default function LandingPageFooter() {
    return (
        <>
            <footer className="footer" style={{ height: "300px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Container>
                    <div style={{ fontSize: "18px", color: "#fff", fontWeight: "400" }}>2022 Virtual Dynamic Labs™, LLC. All rights reserved</div>
                    <Button
                        className="btn-icon btn-neutral btn-simple btn-link mt-3"
                        color="default"
                        href="https://twitter.com/virtualdynlabs"
                        id="tooltip39661217"
                        target="_blank"
                    >
                        <span className="fab fa-twitter" style={{ fontSize: "30px" }} />

                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip39661217">
                        Follow us
                    </UncontrolledTooltip>
                    <Button
                        className="btn-icon btn-neutral btn-simple btn-link ml-3 mt-3"
                        color="default"
                        href="https://discord.gg/6nPBkTxcGU"
                        id="tooltip750293512"
                        target="_blank"
                    >
                        <span className="fab fa-discord" style={{ fontSize: "30px" }} />
                    </Button>
                    <Button
                        className="btn-icon btn-neutral btn-simple btn-link ml-3 mt-3"
                        color="default"
                        href="https://github.com/Virtual-Dynamic-Labs"
                        id="tooltip750293513"
                        target="_blank"
                    >
                        <span className="fab fa-github" style={{ fontSize: "30px" }} />
                    </Button>
                </Container>
            </footer>
        </>
    );
}

import React from "react";
import LandingPageNavbar from "../LandingPage/LandingPageNavbar";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import background from "../LandingPage/Images/metaverse.jpg";

import styled, { keyframes } from "styled-components";
import { fadeInDown } from 'react-animations';

const fadeInDownAnimation = keyframes`${fadeInDown}`;
const SectionHeader = styled.div`
    animation: 3s ${fadeInDownAnimation};
`;

const Deck = ({ deckRef }) => {
    return (
        <div ref={deckRef} style={{ paddingTop: "200px" }}>
            <SectionHeader className="d-flex align-items-center justify-content-center" style={{ fontSize: "50px", color: "#fff", marginBottom: "100px" }}>Pitch Deck</SectionHeader>
            <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTTmfLUB_41JuJSPkNpyh6c3XMsr3JXCzN3ZTKMJjYVRT3coQU_shIhFC94NLMFIA/embed?start=false&loop=false&delayms=10000" frameborder="0" width="100%" height="800" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
        </div>
    )
}

export default Deck;
import React from "react";
import {
  Button,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import styled, { keyframes } from "styled-components";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { bounce, slideInLeft } from 'react-animations';

import background from "./Images/metaverse.gif";
import background2 from "./Images/metaverse.png";

const slideInLeftAnimation = keyframes`${slideInLeft}`;
const bounceAnimation = keyframes`${bounce}`;

const MainHeader = styled.div`
`;

const Description = styled.div`
  width: 670px;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  margin: 40px 0;
`;

const ExploreButton = styled(motion.div)`
  width: 200px;
  height: 50px;
  background: linear-gradient(87.39deg, #15B4F0 50%, #786FF1 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
  margin-top: 30px;
  font-family: Brutal_Light, "Open Sans", arial;
  animation: 1s ${slideInLeftAnimation};
  @media (max-width: 580px) {
    width: 150px;
    height: 45px;
    font-size: 12px;
  }
`;

const LandingTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  font-size: 50px;
  font-weight: bold;
  line-height: 60px;
  width: 100%;
  font-family: Brutal_Light, "Open Sans", arial;
  p {
    font-size: 20px;
  }
  @media (max-width: 580px) {
    font-size: 35px;
    line-height: 40px;
    p {
      font-size: 16px;
    }
  }
  @media (max-width: 460px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

const LandingMain = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .page-header {
    margin: 0 150px;
    @media (max-width: 460px) {
      margin: 0 120px;

    }
  }
`;

const LandingPageContent = ({ mainRef, productRef, handleScroll }) => {
  const { scrollY } = useViewportScroll();
  const scaleRight = useTransform(scrollY, [0, 100], [1.50, 1]);

  return (
    <LandingMain>
      <motion.div ref={mainRef} style={{
        scale: scaleRight,
        backgroundImage: `url(${background})`,
        backgroundSize: "fixed 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
        backgroundPositionY: "40%",
        backgroundPositionX: "50%",
        fontFamily: "Poppins",
        height: "100%"
      }}>
        <div className="page-header">
          <Container style={{ padding: "0px", paddingTop: "200px" }}>
            <LandingTextGroup>
              <p>We are building the</p>
              <MainHeader>Next Generation</MainHeader>
              <MainHeader>Open Metaverse</MainHeader>
              <MainHeader>Infrastructure</MainHeader>
              <ExploreButton whileHover={{ scale: 1.1 }} onClick={() => handleScroll(productRef.current)}>
                EXPLORE PRODUCTS
              </ExploreButton>
            </LandingTextGroup>
          </Container>
        </div>
      </motion.div>
      {/* <Container style={{ padding: "0px", marginTop: "300px" }}>
      </Container> */}
    </LandingMain>
  )
}

export default LandingPageContent;
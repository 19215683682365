import React, { useState } from 'react'
import {
    Button,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";

import { useInView } from 'react-intersection-observer'

import ProductModal from "./Modal/ProductModal";
import { motion } from "framer-motion";

import styled, { keyframes } from "styled-components";
import { fadeInDown } from 'react-animations';

const fadeInDownAnimation = keyframes`${fadeInDown}`;

const SectionHeader = styled.div`
    animation: 3s ${fadeInDownAnimation};
`;

const ProductGroup = styled.div`
    display: flex;
    color: #fff;
    padding-top: 100px;
    @media (max-width: 991px) {
        flex-direction: ${props => props.reverse ? "column-reverse" : "column"};
        justify-content: center;
        align-items: center;
    }
    img {
        border-radius: 4px;
    }
`;

const ProductGroupDesc = styled.div`
    display: flex;
    flex-direction: column;
    align-itmes: center;
    padding: 50px 80px;
    @media (max-width: 991px) {
    }
`;

const ProductGroupDescHeader = styled.div`
    font-size: 50px;
    font-family: Brutal_Light, "Open Sans", arial;
    @media (max-width: 991px) {
        font-size: 45px;
    }

    @media (max-width: 560px) {
        font-size: 40px;
    }
`;

const ProductGroupDescSub = styled.div`
    font-size: 20px;
    font-weight: 400;
    margin: 20px 0;
`;

const LearnMoreButton = styled(motion.div)`
    background: linear-gradient(95.39deg, #16b2ee 80%, #fff00 100%);
    font-size: 20px;
    width: 160px;
    cursor: pointer;
    border-style: hidden hidden solid hidden;
    border-color: #15B4F0;
    border-radius: 1rem;
    text-align: center;
    box-shadow: rgba(21, 180, 240, 0.3) 0px 4px 16px, rgba(21, 180, 240, 0.3) 0px 8px 24px, rgba(21, 180, 240, 0.3) 0px 16px 56px;
`;

const ProductContent = ({ productRef }) => {
    const [showModal, setShowModal] = useState(false);
    const [config, setConfig] = useState('sdk');

    const openModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div ref={productRef} style={{ paddingTop: "500px" }}>
            <SectionHeader style={{ fontSize: "50px", color: "#fff", textAlign: "center", marginBottom: "100px" }}>Our Products</SectionHeader>
            <Container>
                <ProductGroup>
                    <img
                        alt="..."
                        src={require("./Images/cloud.gif").default}
                        width="400px"
                        height="300px"
                    />
                    <ProductGroupDesc>
                        <ProductGroupDescHeader>
                            Open Metaverse Cloud
                        </ProductGroupDescHeader>
                        <ProductGroupDescSub>
                            We offer you the best Open Metaverse Cloud Service to support all you need to build an Open Metaverse game.
                        </ProductGroupDescSub>
                        <LearnMoreButton whileHover={{ scale: 1.15, backgroundColor: '#0099f0' }}
                            onClick={() => {
                                openModal();
                                setConfig('sdk');
                            }}>Learn More</LearnMoreButton>
                    </ProductGroupDesc>
                </ProductGroup>
                <ProductGroup reverse>
                    <ProductGroupDesc>
                        <ProductGroupDescHeader>
                            Cloud App Builder
                        </ProductGroupDescHeader>
                        <ProductGroupDescSub>
                            The One Stop Cloud IDE gives you the full capability to build your Open Metaverse Interoperable Avator or immersive experience.
                        </ProductGroupDescSub>
                        <LearnMoreButton whileHover={{ scale: 1.15, backgroundColor: '#0099f0' }}
                            onClick={() => {
                                openModal();
                                setConfig('appBuilder');
                            }}>Learn More</LearnMoreButton>
                    </ProductGroupDesc>
                    <motion.div>
                        <picture>
                            <img
                                alt="..."
                                src={require("./Images/appbuilder.webp").default}
                                width="400px"
                                height="300px"
                                style={{
                                    boxShadow: "rgba(0, 0, 0, 1.5) 8px 20px 50px"
                                }}
                            />
                        </picture>
                    </motion.div>
                </ProductGroup>
                <ProductGroup>
                    <motion.div>
                        <picture>
                            <img
                                alt="..."
                                src={require("./Images/marketplace.webp").default}
                                width="1000px"
                                height="300px"
                                style={{
                                    boxShadow: "rgba(0, 0, 0, 1.5) 8px 20px 50px"
                                }}
                            />
                        </picture>
                    </motion.div>
                    <ProductGroupDesc>
                        <ProductGroupDescHeader>
                            Open Metaverse App Marketplace
                        </ProductGroupDescHeader>
                        <ProductGroupDescSub>
                            We invest more resources than any other platform in making sure great support from real people is a click away, whenever you need it.
                        </ProductGroupDescSub>
                        <LearnMoreButton whileHover={{ scale: 1.15, backgroundColor: '#0099f0' }} onClick={() => {
                            openModal();
                            setConfig('marketplace');
                        }}>Learn More</LearnMoreButton>
                    </ProductGroupDesc>
                </ProductGroup>
                <ProductGroup reverse>
                    <ProductGroupDesc>
                        <ProductGroupDescHeader>
                            NFT Launchpad
                        </ProductGroupDescHeader>
                        <ProductGroupDescSub>
                            Our NFT lauchpad lets you deploy your asset or cloud app to blockchain with minimum effort.
                        </ProductGroupDescSub>
                        <LearnMoreButton
                            whileHover={{ scale: 1.15, backgroundColor: '#0099f0' }}
                            onClick={() => { openModal(); setConfig('nft'); }}>Learn More</LearnMoreButton>
                    </ProductGroupDesc>
                    <img
                        alt="..."
                        src={require("./Images/nft.gif").default}
                        width="400px"
                        height="300px"
                        style={{
                            boxShadow: "rgba(0, 0, 0, 1) 8px 8px 50px"
                        }}
                    />
                </ProductGroup>
            </Container>
            <ProductModal showModal={showModal} closeModal={closeModal} config={config} />
            <Container style={{ padding: "0px", marginTop: "300px" }}>
            </Container>
        </div>
    )
}

export default ProductContent


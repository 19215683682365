import React, { useRef } from "react";
// core components
import LandingPageNavbar from "./LandingPageNavbar";
import LandingPageContent from "./LandingPageContent"
import LandingPageFooter from "./LandingPageFooter";
import TeamPageContent from "../TeamPage/TeamPageContent";
import ProductContent from "../Product/ProductContent";
import Roadmap from "../Roadmap/Roadmap";
import Community from "../Community/Community";
import Deck from "../Deck/Deck";
/*! Copyright 2022 Virtual Dynamic Labs */
// nodejs library that concatenates classes
// ReactJS plugin for a nice carousel
// reactstrap components
// import {
//   Button,
//   UncontrolledCollapse,
//   Input,
//   NavbarBrand,
//   Navbar,
//   NavItem,
//   NavLink,
//   Nav,
//   Container,
//   Row,
//   Col,
// } from "reactstrap";

export default function Landing() {
  const mainRef = useRef(null);
  const teamRef = useRef(null);
  const productRef = useRef(null);
  const roadmapRef = useRef(null);
  const deckRef = useRef(null);

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div style={{ background: "#070914" }}>
      <LandingPageNavbar mainRef={mainRef} teamRef={teamRef} productRef={productRef} deckRef={deckRef} handleScroll={handleScroll} />
      <LandingPageContent mainRef={mainRef} productRef={productRef} handleScroll={handleScroll} />
      <ProductContent productRef={productRef} />
      <TeamPageContent teamRef={teamRef} />
      <Community />
      <LandingPageFooter />
    </div>
  );
}

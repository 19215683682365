import React from 'react'
import background from "./Images/bg.png";
import background_mobile from "./Images/mobile_background.png";

import {
    Container,
} from "reactstrap";
import styled from 'styled-components';

const CommunityButton = styled.div`
    background: #35373E;
    font-size: 25px;
    width: 250px;
    padding: 20px;
    text-align: center;
    border: 1px solid #fff;
    cursor: pointer;
    a, a:visited{
     color: #fff;
     font-weight: bold;
    }
`;

const CommunityBackground = styled.div`
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    width: 100%;
    color: #fff;
    padding: 150px;
    margin-top: 200px;
    font-weight: bold;
    @media (max-width: 975px) {
        background-image: url(${background_mobile});
        background-size: 100% 100%;
        height: 100%;
        padding: 125px 75px;
    }
    
`;

const CommunityGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 975px) {
        flex-direction: column;
    }
`;

const CommunityHeader = styled.div`
    font-size: 50px;
    @media (max-width: 975px) {
        font-size: 43px;
    }
`;

const CommunitySubGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: ${props => (props.transform ? "translate(110px, 0px)" : "none")};
    @media (max-width: 975px) {
        padding-top: 50px;
        transform: none;
    }
`;

const Community = () => {
    return (
        <CommunityBackground>
            <Container>
                <CommunityGroup>
                    <CommunitySubGroup>
                        <CommunityHeader>JOIN OUR COMMUNITY</CommunityHeader>
                        <div style={{ display: "flex", marginTop: "30px" }}>
                            <CommunityButton><a href="https://www.linkedin.com/company/virtual-dynamic-labs/" target="_blank" rel="noreferrer">Join Us</a></CommunityButton>
                            <CommunityButton style={{ marginLeft: "50px" }}><a href="https://discord.gg/6nPBkTxcGU" target="_blank" rel="noreferrer">Join Discord</a></CommunityButton>
                        </div>
                    </CommunitySubGroup>
                    <CommunitySubGroup transform>
                        <CommunityHeader>WHITEPAPER</CommunityHeader>
                        <div style={{ display: "flex", marginTop: "30px" }}>
                            <CommunityButton style={{ width: "300px" }}><a href="https://docs.google.com/document/d/e/2PACX-1vS-kIkhtOeANBcCW_LVLNr6TifEzAZP3ASNAeRnM5TXIR29ndaQeHt-XNKmfOCUG5j7qgXlH7RMoPyI/pub" target="_blank" rel="noreferrer">View Whitepaper</a></CommunityButton>
                        </div>
                    </CommunitySubGroup>
                </CommunityGroup>
            </Container>
        </CommunityBackground>
    )
}

export default Community
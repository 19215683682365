import React from "react";
import LandingPageNavbar from "../LandingPage/LandingPageNavbar";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import background from "../LandingPage/Images/metaverse.jpg";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col,
} from "reactstrap";
import bg from "./images/bg.png";

import styled, { keyframes } from "styled-components";
import { fadeInDown } from 'react-animations';
import { motion } from "framer-motion";

const fadeInDownAnimation = keyframes`${fadeInDown}`;
const SectionHeader = styled.div`
    font-family: Brutal_Light, "Open Sans", arial;
    animation: 3s ${fadeInDownAnimation};
`;

const ProfileGroup = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 380px;
    height: 480px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 16px;
    box-shadow: rgba(21, 180, 240, 0.3) 0px 4px 16px;
    margin: 20px;
    padding: 30px 20px;
    background: rgba(0, 0, 0, 1);
    font-size: 20px;
    color: white;
    position: relative;
    .front, .back {
        position: absolute;
        backface-visibility: hidden;
        transition: transform .4s linear;
        overflow: hidden;
    }
    .front {
        transform: perspective(600px) rotateY(0deg);
    }
    .back {
        opacity: 0;
        transition: opacity 1s;
        transform: perspective(600px) rotateY(180deg);
        width: 100%;
        height: 100%;
        border: 1px solid transparent;
        border-radius: 16px;
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 500;
        font-size: 20px
    }
    &:hover > .front {
        transform: perspective(600px) rotateY(-180deg);
    }
    &:hover > .back {
        opacity: 1;
        transform: perspective(600px) rotateY(0deg);
    }
`;

const GradientName = styled.div`
    font-size: 25px;
    background: -webkit-linear-gradient(45deg, #25C5D6 14.74%, #A685FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const MainContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 1300px;
    margin: 0 auto;
    @media (max-width: 1200px) {
        flex-wrap: none;
        flex-direction: column;
        width: 500px;
    }
`;

const Profile = ({ config }) => {
    const { name, title, image, twitter, linkedin, bullets } = config;
    return (
        <ProfileGroup whileHover={{ scale: 1.1, backgroundColor: '#0099f0', boxShadow: "rgba(21, 180, 240, 0.3) 60px 4px 16px;" }}>
            <div className="front" >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={require(`${image}`).default} width="307px" height="307px" alt="" />
                </div>
                <div style={{ display: "flex", flexDirection: "column", padding: "20px 0" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                        <GradientName>{name}</GradientName>
                    </div>
                    <div>{title}</div>
                </div>
            </div>
            <div className="back d-flex justify-content-between">
                <div>
                    {bullets.map((el) => (
                        <div style={{ marginBottom: "15px" }}>
                            {'• ' + el}
                        </div>
                    ))}
                </div>
                <div className="d-flex aling-items-center justify-content-center">
                    <Button
                        className="btn-icon btn-round"
                        color="twitter"
                        href={twitter}
                        target="_blank"
                    >
                        <i className="fab fa-twitter" />
                    </Button>
                    <Button
                        className="btn-icon btn-round ml-1"
                        color="linkedin"
                        href={linkedin}
                        target="_blank"
                    >
                        <i className="fab fa-linkedin" />
                    </Button>
                </div>
            </div>
        </ProfileGroup>
    )
}

const TeamPageContent = ({ teamRef }) => {
    const allConfigs = [
        {
            name: "Ziyao Zhou",
            title: "CEO",
            image: "./images/michael-zhou.jpeg",
            twitter: "https://twitter.com/MichaelZhou10",
            linkedin: "https://www.linkedin.com/in/ziyao-zhou-57b41485/",
            bullets: [
                "Software engineer at Google, Ex-Qualcom",
                "CTO of Melon Network Inc.",
                "Years of experience in Cloud, Blockchain and 5G Telecom Industry. Founder of Melon Network Inc, (A Social Crypto Wallet Company) and Therapu.com (Online therapy)"]
        },
        {
            name: "Allen Chang",
            title: "Blockchain Developer",
            image: "./images/allen-chang.jpeg",
            twitter: "https://twitter.com/Deztiny1742",
            linkedin: "https://www.linkedin.com/in/sheng-yu-chang-67a442186/",
            bullets: ["Software engineer at Tealium", "Lead of Frontend SaaS products (e.g. Cloud Code, Customer Data Platform), NFTVeteran (Launched multiple success projects (including 'L.S.D by Deztny)'", "OG NFTer and Enthusiastic Gamer"]
        },
        {
            name: "Brandon Liu",
            title: "Product Manager",
            image: "./images/brandon-liu.jpeg",
            twitter: "https://twitter.com/brandon_liu_bc",
            linkedin: "https://www.linkedin.com/in/brandon-liu-33b45585/",
            bullets: ["Sr. Frontend Software Engineer at ServiceNow Application Dev SDK Team", "Expert in SaaS SDK Development, Data Privacy Software, and full-stack development"]

        },
        {
            name: "Vincent Li",
            title: "Cloud Architect",
            image: "./images/vincent-li.jpeg",
            twitter: "https://twitter.com/vincent8540",
            linkedin: "https://www.linkedin.com/in/ziyao-zhou-57b41485/",
            bullets: ["Stanford Alumni, Software engineer at Amazon Alexa Infra Team, ex-Oracle", "Years of experience in Machine Learning Infrastructure and Distributed System Architect", "Open Metaverse Supporters"]
        },
        {
            name: "Liana Lei",
            title: "Business Advisor",
            image: "./images/liana-lei.jpeg",
            twitter: "https://twitter.com/Liana_Lei_Manyi",
            linkedin: "https://www.linkedin.com/in/manyi-liana-lei/",
            bullets: ["BCG Consultant focusing on TMT industries and consumer insights", "Serial-entrepreneur (past ventures include AI recommendation algorithm for beauty products and NFT + Lab grown diamonds)", "Expert in Business Development & Alliance management"]
        },
    ]
    return (
        <div ref={teamRef} style={{ paddingTop: "200px" }}>
            <SectionHeader style={{ fontSize: "50px", color: "#fff", textAlign: "center", marginBottom: "100px" }}>Meet Our Team</SectionHeader>
            <MainContainer>
                {allConfigs.map((config) => (
                    <Profile config={config} />
                ))}
            </MainContainer>
        </div>
    )
}
export default TeamPageContent;
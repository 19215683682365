import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import styled from "styled-components";
import { motion } from "framer-motion";

// const ModalControl = styled(Modal)`
//   .ReactModal__Overlay {
//     opacity: 0;
//     transition: opacity 2000ms ease-in-out;
//   }

//   .ReactModal__Overlay--after-open{
//       opacity: 1;
//   }

//   .ReactModal__Overlay--before-close{
//       opacity: 0;
//   }
// `;

const MainWrap = styled.div`
  padding: 60px 60px;
  color: #fff;
  height: 100%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  font-family: Poppins;
  @media (max-width: 975px) {
    flex-wrap: wrap;
    flex-direction: column;
    padding: 40px 30px;
  }
`;

const MainHeader = styled.div`
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 50px;
  font-family: Brutal_Light, "Open Sans", arial;
  @media (max-width: 975px) {
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 500px) {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MainPhrase = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 80px 80px;
`;

const PhraseGroup = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  width: 500px;
`;

const PhraseTitle = styled.div`
  font-size: 31px;
  margin-bottom: 10px;
  font-family: Brutal_Light, "Open Sans", arial;
  @media (max-width: 500px) {
    font-size: 25px;
  }
`;

const PhraseSub = styled.div`
  color: rgb(173, 173, 190);
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const CloseButton = styled.div`
  width: 150px;
  height: 65px !important;
  background: #0099f0;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
  border-color: #0099f0;
  font-family: Brutal_Light, "Open Sans", arial;
  box-shadow: rgba(21, 180, 240, 0.3) 0px 4px 16px, rgba(21, 180, 240, 0.3) 0px 8px 24px, rgba(21, 180, 240, 0.3) 0px 16px 56px;
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "1250px",
    height: "850px",
    background: "linear-gradient(87.39deg, #070914 50%, #000000 80%)",
    // backdropFilter: 'blur(107.5px)',
    borderRadius: '10px',
    boxShadow: 'rgba(21, 180, 240, 0.3) 0px 4px 16px, rgba(21, 180, 240, 0.3) 0px 8px 24px, rgba(21, 180, 240, 0.3) 0px 16px 56px',
  },
  overlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    zIndex: 1000,
  }
};

const mainConfig = {
  'sdk': [
    {
      title: '3D Assets Storage & CDN',
      content: [
        `Provide a safe and cheap cloud storage for 3D objects or assets`,
        'Support uploading assets for cloud gaming',
        'Allow high quality 3D assets rendering'
      ]
    },
    {
      title: 'Blockchain Connectivity',
      content: [
        `Realtime blockchain data monitoring`,
        '3D NFT asset and animation hosting',
        'Support variety of smart contract interaction APIs'
      ]
    },
    {
      title: 'Metaverse Cloud App',
      content: [
        'Support Metaverse in-game applications to run the code on the cloud',
        'Share the same application experience cross different Metaverse games',
        'Deploy once and use everywhere'
      ]
    },
    {
      title: 'Open Metaverse Interoperability',
      content: [
        'Seamless move objects between games',
        'Support OpenXR standard of object interactions',
        'Good support of physic system in different games'
      ]
    },
  ],
  'appBuilder': [
    {
      title: 'Strong Development Experience',
      content: [
        "Strong JS language support",
        "Compile code on the cloud",
        "Github repository support"
      ]
    },
    {
      title: 'Easy-to-use UI',
      content: [
        "Application flow chart tool",
        "Cool editor support",
        "Supports external VSCode plugin"
      ]
    },
    {
      title: 'Collaboration',
      content: [
        'Team can build their application together through the cloud platform'
      ]
    },
    {
      title: 'Cross Platform',
      content: [
        'Easily deploy your 3D asset to different game engine or environment'
      ]
    },
  ],
  'marketplace': [
    {
      title: 'App Developer/NFT Content Creator',
      content: [
        "Monetize Open Metaverse Cloud App",
        "Monetize 3D avatar NFT",
        "Exchange Open Metaverse in-game Token"
      ]
    },
    {
      title: 'Metaverse Provider',
      content: [
        "Application flow chart tool",
        "Cool editor support",
        "Supports external VSCode plugin"
      ]
    },
  ],
  'nft': [
    {
      title: 'Easy Deployment',
      content: [
        `Deploy 3D avatar to blockchain with a few clicks`,
        'No need to understand blockchain technology'
      ]
    },
    {
      title: 'Smart Contract Template',
      content: [
        `Variety of smart contract template choices`,
        'Safe and well audited'
      ]
    },
    {
      title: 'Data Analysis',
      content: [
        'Provide a data analytic tool to track the sale or the usage of NFT projects'
      ]
    },
    {
      title: 'Open Metaverse Interoperability',
      content: [
        'NFT assets are supported well by the game integrated with DynoV Open Metaverse SDK'
      ]
    },
  ]
}


const ProductModal = styled(({ showModal, closeModal, config }) => {
  const [viewPort, setViewPort] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight
  });
  let title = "";
  switch (config) {
    case 'sdk':
      title = "Open Metaverse Cloud";
      break;
    case 'appBuilder':
      title = "Cloud App Builder";
      break;
    case 'marketplace':
      title = "Marketplace";
      break;
    case 'nft':
      title = "NFT Launchpad";
      break;
    default:
      title = "";
  }

  useEffect(() => {
    const setSize = () => {
      setViewPort({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
      })
    }
    window.addEventListener('resize', setSize)
    return () => {
      window.removeEventListener('resize', setSize)
    }
  }, [])
  if (viewPort?.width < 500) {
    customStyles.content.width = "450px";
  } else if (viewPort?.width < 1010) {
    customStyles.content.width = "650px";
  } else {
    customStyles.content.width = "1250px";
  }
  return (
    <Modal
      isOpen={showModal}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={200}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <MainWrap>
          <MainHeader>{title}</MainHeader>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "85%" }}>
            <MainPhrase>
              {mainConfig[config].map((el) => {
                return (
                  <PhraseGroup>
                    <PhraseTitle>{el.title}</PhraseTitle>
                    <PhraseSub>
                      {el.content.map((c) => <div>{c}</div>)}
                    </PhraseSub>
                  </PhraseGroup>
                )
              })}
            </MainPhrase>
            <CloseButton onClick={closeModal}>Back</CloseButton>
          </div>
        </MainWrap>
      </motion.div>
    </Modal>
  )
})`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 2000ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }
  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
`;

export default ProductModal